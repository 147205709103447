<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<div v-for="(item,index) in vipInfo" :key="index">
			<div class="orderList">
				<div class="orderList-content">
					<div class="first">{{ item.type_name }}会员计划</div>
					<div class="second">开通年卡即享折扣会员</div>
					<div class="third">
						<div>折扣卡</div>
						<div>会员费</div>
						<div>会员特权/条件</div>
					</div>
					<div class="fourth">
						<div style="margin-left: 8%;">{{item.discount}}折</div>
						<div class="money">{{ item.price }}元</div>
						<div style="width: 120px;">
							<div>{{ item.activity }}</div>
							<div>余额大于0</div>
						</div>
					</div>
					<div class="fifth" @click="handle(item.level_id)">办理会员</div>
				</div>
			</div>
		</div>

		<!-- vip弹框 -->

		<el-dialog title="提示" :visible.sync="vipShow" width="80%"style="margin-top: 100px;" center>
			<div class="govip">确定要办理会员吗？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="buy" style="background: #09253d; border: none; width: 100px">确 定
				</el-button>
				<el-button @click="vipShow = false" style="
		        background: #c92323;
		        color: #ffffff;
		        border: none;
		        width: 100px;
		      ">取 消</el-button>
			</span>
		</el-dialog>

		<!-- svip弹框 -->
		<el-dialog title="提示" :visible.sync="svipShow" width="80%"style="margin-top: 100px;" center>
			<div class="govip">确定要办理超级会员吗？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="buy" style="background: #09253d; border: none; width: 100px">确 定
				</el-button>
				<el-button @click="svipShow = false" style="
		        background: #c92323;
		        color: #ffffff;
		        border: none;
		        width: 100px;
		      ">取 消</el-button>
			</span>
		</el-dialog>

		<!-- 原创会员弹框 -->
		<el-dialog title="提示" :visible.sync="originalShow" width="80%"style="margin-top: 100px;" center>
			<div class="govip">确定要办理原创会员吗？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="buy" style="background: #09253d; border: none; width: 100px">确 定
				</el-button>
				<el-button @click="originalShow = false" style="
		        background: #c92323;
		        color: #ffffff;
		        border: none;
		        width: 100px;
		      ">取 消</el-button>
			</span>
		</el-dialog>

		<!-- 月卡会员弹框 -->
		<el-dialog title="提示" :visible.sync="monthShow" width="80%"style="margin-top: 100px;" center>
			<div class="govip">确定要办理月卡会员吗？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="buy" style="background: #09253d; border: none; width: 100px">确 定
				</el-button>
				<el-button @click="monthShow = false" style="
		        background: #c92323;
		        color: #ffffff;
		        border: none;
		        width: 100px;
		      ">取 消</el-button>
			</span>
		</el-dialog>

		<!-- 季卡会员弹框 -->
		<el-dialog title="提示" :visible.sync="seasonShow" width="80%"style="margin-top: 100px;" center>
			<div class="govip">确定要办理季卡会员吗？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="buy" style="background: #09253d; border: none; width: 100px">确 定
				</el-button>
				<el-button @click="seasonShow = false" style="
		        background: #c92323;
		        color: #ffffff;
		        border: none;
		        width: 100px;
		      ">取 消</el-button>
			</span>
		</el-dialog>
		<!-- 余额不足弹框 -->
		<el-dialog title="" :visible.sync="lackBalance" width="80%"style="margin-top: 100px;" center>
			<div class="govip">您的余额不足，请充值！</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="topup" style="background: #09253d; border: none; width: 100px">去充值
				</el-button>
			</span>
		</el-dialog>
		<info />
		<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
		<foot />
	</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import {
		userVipInfo,
		rechargeCard
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				vipInfo: "", //vip 信息
				svipInfo: "", //svip 信息
				userInfo: '', //用户会员信息
				vipShow: false,
				svipShow: false,
				seasonShow: false,
				monthShow: false,
				originalShow: false,
				lackBalance:false,
				type_id: "",
			};
		},

		created() {
			this.getUserVipInfo();
		},
		methods: {
			//获取用户会员信息
			getUserVipInfo() {
				userVipInfo({}).then((res) => {
					console.log(res);
					// this.vipInfo = res.data.vipInfo
					// this.svipInfo = res.data.svipInfo
					this.userInfo = res.data.user_levels
					this.vipInfo = res.data.vip_levels


				});
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
			handle(level_id) {
				switch (level_id) {
					case 2:
						this.vipShow = !this.vipShow;
						this.type_id = 1
						break;
					case 3:
						this.svipShow = !this.svipShow;
						this.type_id = 2
						break;
					case 4:
						this.originalShow = !this.originalShow;
						this.type_id = 4
						break;
					case 5:
						this.monthShow = !this.monthShow;
						this.type_id = 5
						break;
					case 6:
						this.seasonShow = !this.seasonShow;
						this.type_id = 6
						break;
				}
			},
			//去充值
			topup() {
				this.$router.push({
				  name: 'Recharge',
				});
				this.lackBalance = false
			},
			buy() {

				console.log(this.type_id)
				// return
				rechargeCard({
					type_id: this.type_id,
					pay_id: 0,
				}).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});

						this.vipShow = false;
						this.svipShow = false;
						this.originalShow = false;
						this.monthShow = false;
						this.seasonShow = false;
						this.getUserVipInfo();
					}

					if (res.code == 500) {
						this.lackBalance = true;
						this.vipShow = false;
						this.svipShow = false;
						this.originalShow = false;
						this.monthShow = false;
						this.seasonShow = false;
					}
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		background: #F6F6F6;

		.orderList {
			margin-top: 15px;
			padding: 0 10px;

			.orderList-content {
				padding: 18px 0px;
				font-size: 13px;
				background-color: #FFFFFF;
				color: #000000;
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;

				.first {
					font-size: 14px;
				}

				.first::after {
					content: "";
					width: 86px;
					height: 1px;
					background: linear-gradient(-90deg, #ffffff, #08243c, #ffffff);
					display: block;
					margin-top: 9px;
				}

				.second {
					color: #08243C;
					font-size: 12px;
					margin-top: 9px;
				}

				.third {
					font-size: 13px;
					margin-top: 29px;
					width: 85%;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.fourth {
					margin-top: 29px;
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.money {
						position: absolute;
						left: 43%;
						transform: translateX(-43%)
					}
				}

				.fifth {
					border-radius: 2px;
					margin-top: 35px;
					background-color: #08243C;
					color: #FFFFFF;
					width: 150px;
					height: 35px;
					line-height: 35px;
					text-align: center;
				}
			}
		}

		.govip {
			font-size: 13px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			text-align: center;
			margin-top: 15px;
		}
	}
</style>
