<template>
	<div class="page">
		<div class="dist">
			<div class="dist-content">
				<div class="dist-content-left">
					<img :src="imageUrl(head_pic)" alt="">
				</div>
				<div class="dist-content-mid">
					<div class="dist-content-mid-top">{{ nickname }}</div>
					<div class="dist-content-mid-down">
						<div>余额</div>
						<div style="margin-left: 10px;">￥{{ users_money }}</div>
					</div>
				</div>
				<div class="dist-content-right">
					<div style="margin-right: 10px;">充值金额</div>
					<div>￥{{ moneyOrder }}</div>
				</div>
			</div>
			<div class="dist-button">
				<div  @click="go('Recharge')">充值</div>
				<div @click="go('Balance')">余额提现、管理</div>
				<div  @click="loginOut">退出登录</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { loginOut,memberRightData } from "../../request/api";
	export default {
		data() {
			return {
				ConAmount: "", //消费金额
				moneyOrder: "", //充值金额
				nickname: "", //名称
				head_pic: "", //头像
				users_money: "", //余额
				url: "/buyer/buyOrder",
			};
		},

		created() {
			this.getData();
		},
		methods: {
			loginOut() {
				loginOut({}).then((res) => {
					console.log(res);
			
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: "success",
						});
			
						localStorage.removeItem("head_pic");
						localStorage.removeItem("nickname");
						this.$router.push({
							name: 'Login',
						});
					}
				});
			},
			getData() {
			  memberRightData({
			    type: 1,
			  }).then((res) => {
			    this.nickname = res.data.userData.nickname;
			    this.head_pic = res.data.userData.head_pic;
			    this.users_money = res.data.userData.users_money;
			    this.moneyOrder = res.data.moneyOrder;
			    this.ConAmount = res.data.ConAmount;
			  });
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		.dist {
			width: 92%;
			margin: 13px 2% 13px 2%;
			padding: 12px 2% 18px 2%;
			background-color: #FFFFFF;

			.dist-content {
				display: flex;
				justify-content: space-between;
				color: #08243C;
				font-size: 12px;

				.dist-content-left {
					img {
						border-radius: 50%;
						width: 60px;
						height: 60px;
					}
				}

				.dist-content-mid {
					position: absolute;
					left: 37%;
					transform: translateX(-37%);

					.dist-content-mid-top {
						font-size: 14px;
						margin-top: 10px;
					}

					.dist-content-mid-down {
						display: flex;
						align-items: center;
						margin-top: 13px;
					}
				}

				.dist-content-right {
					margin-top: 25px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}

			.dist-button {
				padding: 0 11px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 13px;
				font-size: 12px;

				div {
					padding: 11px 16px;
					color: #FFFFFF;
					background-color: #08243C;
					border-radius: 16px;
				}
			}
		}
	}
</style>
